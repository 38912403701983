import React from "react";
import PropTypes from "prop-types";
import * as app from "../services/AppService";
export default function StatisticDiv(props) {
    return (
        <div className={`rounded border border-1 ${props.Class + props.ExtentionClass}`}>
            <div className={`text-start mx-1 my-1 ${props.LabelClass}`}>
                {app.translate(props.Label)}
                <div className={`d-inline-block mx-2 fw-bold ${props.ResultClass}`}>{props.Result}</div>
            </div>
        </div>
    );
}

StatisticDiv.propTypes = {
    Label: PropTypes.string.isRequired,
    Result: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Class: PropTypes.string,
    ExtentionClass:PropTypes.string,
    LabelClass: PropTypes.string,
    ResultClass: PropTypes.string,
};
StatisticDiv.defaultProps = {
    Class: "col-12 col-lg-auto bg-body border-primary-subtle shadow-sm ",
    LabelClass: "text-primary-emphasis fw-normal",
    ResultClass: "text-success-emphasis ",
};
