import React from "react";
import * as app from "../services/AppService";
import PropTypes from "prop-types";

export default function Switches(props) {
  function handler(event) {
    const { checked } = event.target;
    if (props.OnChange && typeof props.OnChange == "function")
      props.OnChange(checked);
  }
    return (
        <div className={`form-check form-switch mx-2 mt-1 ${props.Class}`}>
          <input
              id={props.ID ? props.ID : "swch" + props.Label}
        type="checkbox"
        checked={props.Checked}
        name={props.Name}
        disabled={props.Disabled}
        className="form-check-input"
        onChange={handler}
      />
      <label
        htmlFor={props.Name}
        className={"ms-2 form-check-label text-body "}
      >
        {app.translate(props.Label)}
      </label>
    </div>
  );
}

Switches.propTypes = {
  OnChange: PropTypes.func.isRequired,
  Name: PropTypes.string,
  Disabled: PropTypes.bool,
  Class: PropTypes.string,
  Label: PropTypes.string,
};
Switches.defaultProps = {
  Class: "",
  Name: "sch",
  Label: "",
  Disabled: false,
};
