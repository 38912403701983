import React from 'react';
import { useSelector } from "react-redux"
import * as app from "../services/AppService"
import Version from "../utils/version";
const EnStyle = React.lazy(() => import('../utils/styles/BestRate'));

export default function SmallSizeLayout() {
    const optionInfo = useSelector(store => store.data.options);

    return (
        <div className="card rounded-0 min-vh-100 d-block d-md-none" style={{ background: "#1a1a19" }}>
            <div className="d-flex align-items-center justify-content-center card-body min-vh-100">
                <div className="justify-content-center ">
                    <span className="iconssc-cancel d-flex justify-content-center text-light display-1"></span>
                    <h2 className="fw-bold text-light text-center display-5">
                        This page is unavailable
                    </h2>
                    <p className="fw-bold text-light text-center ">
                        الموقع لا يدعم هذا الحجم من الشاشات
                    </p>
                    <p className="fw-bold text-light text-center ">
                        the content you're looking for doesn't exist in this screen size.
                    </p>
                    <p className="fw-bold text-light text-center ">
                        You can rotate the device to get a better viewing experience
                    </p>
                    <p className="fw-bold text-light text-center ">
                        يمكنك تدوير الجهاز للحصول على تجربة افضل في العرض
                    </p>
                    <span className="iconssc-update d-flex justify-content-center text-light display-1"></span>
                </div>
            </div>
        </div>
    );
}