import React from "react";
import * as app from '../../services/AppService';
import { Button, Row } from "../../components";
import { useSelector } from "react-redux"
export default function Index(props) {
    const optionInfo = useSelector(store => store.data.options);

    return (
        <>
            <div className="offcanvas offcanvas-start show sticky-xl-top z-7" style={optionInfo.EnableLivePrices ? { height: "calc(100vh - 88px)" } : {}} tabIndex="-1" id="bdSidebar" aria-labelledby="bdSidebarOffcanvasLabel">
                <div className="offcanvas-body ">
                    <div >
                        <>
                            {
                                props.Level>0?
                                   <Row>
                                        <Button
                                        colsize="auto"
                                        Icon={`${!app.translate("Lang") === "ar" ? 'right-arrow-light' : 'left-arrow-light' } fs-4 text-white`}
                                        Clicked={ props.Back}
                                        />
                                        <Button
                                            Class={`w-100 h-100 bg-secondary text-white fw-bold`}
                                        Label={ props.Parentname }
                                        Clicked={() => {}}
                                        />
                                    </Row>
                                :
                                <Button
                                        Icon="home" IconSize="5"
                                        Class={`w-100 btn-primary py-1 d-flex justify-content-between align-items-center`}
                                        Label='Home'
                                        Clicked={props.Back}
                            />
                            }
                            {props.Menu.map((item, index) => (
                                <div className={`mt-1`} key={index}>
                                    <Button
                                        Icon={`${item.ID && item.ID != 1 && item.ID != 9 && item.ID != 6 ? (!app.translate("Lang") === "ar" ? 'right-arrow' : 'left-arrow') : ""}`}
                                        Class={`w-100 py-1 d-flex justify-content-between align-items-center ${props.ActiveIndex == index ? 'text-white btn-primary' : 'text-primary-emphasis border-1 border-primary'}`}
                                        Label={item.name}
                                        Clicked={() => {props.ButtonClicked(item)}}
                                    />
                                </div>
                            ))}
                        </>
                    </div>

                </div>

                <div className="m-2" >
                    <span className={` d-flex justify-content-center mb-2  border-bottom border-1 ${props.ShowAgentInfo ? 'iconssc-down-arrow-2' : 'iconssc-up-arrow-light '}`}
                        onClick={props.AgentInfoClicked}></span>
                    {props.ShowAgentInfo && <div>
                        <h5 className="text-secondary-emphasis fw-bold">{app.translate('Welcome')}</h5>
                        <h5 className="text-primary-emphasis fw-bold">{app.options().userName}</h5>
                        <p className="text-dark-emphasis"><b>{app.translate('Branch')} :</b>  {app.options().Authorized_person}</p>
                    </div>
                    }
                    <div className="d-flex justify-content-center">
                        <Button
                            Icon='settings'
                            Class={`w-100 text-white py-1 d-flex justify-content-between align-items-center ${props.Parentname === 'HelpWindows' ? 'btn-primary' : 'btn-secondary'}`}
                            Disabled={props.Parentname === 'HelpWindows'}
                            Label='HelpWindows'
                            Clicked={() => { props.ButtonClicked({ ID: 7, name: 'HelpWindows' }); }}
                        />
                    </div>
                </div>

            </div>
        </>
    )
}