import React from 'react';
import PropTypes from "prop-types";
import * as app from "../services/AppService";

function Textarea(props) {

    function handler(event) {
        const { value } = event.target;
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange(value, props.Key);
    }

    let textarea = (<textarea type="text" rows={props.Rows} cols={props.Cols}
        id={props.ID ? props.ID : "txt" + props.Label}
        key={props.Key}
        data-bs-trigger={props.Hoover ? "hover":""}
        data-bs-toggle={props.Hoover ? "popover" : ""}
        data-bs-content={props.Hoover ? props.Hoover : ""}
        className={"form-control form-control-sm border rounded " +  `${props.IsTable ? " border-1  text-center " + props.TableClass : props.Class}`}
        disabled={props.Disabled}
        onChange={handler} value={props.Model}
        onBlur={props.OnBlur}
        placeholder={app.translate(props.Placeholder) + props.Symbol}
        
    />)
    return (props.IsTable ? textarea :
        <div className="mt-2">
            {props.WithLabel && <label className={`${props.LabelClass}`}>{app.translate(props.Label)}</label>}
            <div className={`input-group border rounded  ${props.ContainerClass}`}>
                {textarea}
            </div>
        </div>
    );
}

Textarea.propTypes = {
    Label: PropTypes.string,
    Class: PropTypes.string,
    LabelClass: PropTypes.string,
    TableClass: PropTypes.string,
    Disabled: PropTypes.bool,
    Type: PropTypes.string,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    Placeholder: PropTypes.string,
    OnChange: PropTypes.func,
    Key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    IsTable: PropTypes.bool,
    OnBlur: PropTypes.func,
    Symbol: PropTypes.string,
}
Textarea.defaultProps = {
    Class: " border-0 ",
    LabelClass: "text-primary-emphasis",
    TableClass: "",
    Type: "text",
    Placeholder: "",
    ContainerClass: " ",
    Disabled: false,
    Key: "input",
    IsTable: false,
    Symbol: "",
    WithLabel: true,
}
export default Textarea;