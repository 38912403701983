import React from "react";
import PropTypes from "prop-types";
import * as app from '../services/AppService'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js' 

function DropdownButton(props) {

    function closeMenu(e) {
        if (e && e.target && e.target.classList && e.target.classList.contains("dropdown-item")) return;//اغلاق المنيو الا اذا كان الزر المضغوط لمنوي تاني
        let toggleBtn = document.getElementById(props.ID)
        let dropdownEl = new bootstrap.Dropdown(toggleBtn);
        dropdownEl.hide();
    }

    return (
        <div className={"btn-group " + props.Class}>

            <button className={`btn btn-sm dropdown-toggle  ${props.LabelClass}`} data-bs-auto-close={props.AutoClose}
                data-bs-toggle="dropdown" aria-expanded="false" id={props.ID}>
                {app.translate(props.Label)}
                {props.Icon && <span className={`iconssc-${props.Icon}`}></span>}
            </button>

            <ul className={"dropdown-menu" + props.ItemClass} aria-labelledby="dropdownMenuButton" onClick={closeMenu}>
                {props.children ?
                    Array.isArray(props.children) && props.children[0].type == "li" ? props.children:
                    <li>{props.children}</li>
                    :
                    props.Options.map((item, index) => {
                        return (
                            <li key={index}>
                                <button className="dropdown-item fw-bold" style={{ "fontSize": "12px" }} onClick={() => props.SNclicked(item[props.Key], props.Data || [])}>
                                    {item[props.Value]}
                                </button>
                                <hr className="m-0 dropdown-divider" />
                            </li>

                        );
                    })
                }
            </ul>
        </div>
    )
}


DropdownButton.propTypes = {
    Options: PropTypes.array,
    Data: PropTypes.array,
    Class: PropTypes.string,
    LabelClass: PropTypes.string,
    ItemClass: PropTypes.string,
    Disabled: PropTypes.bool,
    Placeholder: PropTypes.string,
    Label: PropTypes.string,
    Icon: PropTypes.string,
    Value: PropTypes.string,
    Key: PropTypes.string,
    Table: PropTypes.bool,
    AutoClose: PropTypes.string
}

DropdownButton.defaultProps = {
    Options: [],
    Data: [],
    Disabled: false,
    Label: '',
    Class: '',
    LabelClass: 'btn-primary ',
    ItemClass: ' mx-1 p-1 ',
    Value: "Value",
    Key: "Key",
    AutoClose: "auto",
    Table: false,
}
export default DropdownButton;