import React from "react";
import PropTypes from "prop-types"
import * as app from "../services/AppService";
import * as http from "../services/http";
import NavDropdown from "react-bootstrap/NavDropdown";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function ColorMode(props) {


    function setMode(mode) {
        if (mode == "auto")
            app.eraseCookie("colorMode")
        else
            app.setCookie("colorMode", mode, 365);
        window.location.reload()
    }

    return (

        <OverlayTrigger key={'Modebottom'} placement='bottom'
            overlay={
                <Tooltip style={{ position: "fixed" }} id={`tooltip-bottom`}>
                    {app.translate("ScreenMode")}
                </Tooltip>
            }>
            <NavDropdown className="fw-light mx-1 mt-1 text-center"
                title={<span className={` iconssc-${app.getCookie("colorMode", "auto")} ${props.TitleClass}`}></span>} id="nav-dropdown" align="end">
                <NavDropdown.Item onClick={() => setMode("light")}>
                    <span className="iconssc-light mx-1"></span>
                    <span>Light</span>
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => setMode("dark")} >
                    <span className="iconssc-dark mx-1"></span>
                    <span>Dark</span>
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => setMode("auto")}>
                    <span className="iconssc-auto mx-1"></span>
                    <span>Auto</span>
                </NavDropdown.Item>
            </NavDropdown>
        </OverlayTrigger>

    );
}

ColorMode.propTypes = {
    TitleClass: PropTypes.string,
}

ColorMode.defaultProps = {
    TitleClass: "text-primary-emphasis fs-6"
}