import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import * as app from "../services/AppService"
import Tab  from "./Tab"

export default function Tabs(props) {

    function selectedChangedHandler(item) {
        if (props.onSelect)
            props.onSelect(item.id);
        if (item.Clicked)
            item.Clicked();
        if (item.clicked)
            item.clicked();
    }

    var children = (props.children[0] != undefined ? props.children : [props.children]).filter(r => typeof (r) == "object");

    useEffect(() => {
        if (!props.activeTab && children[0].props.Clicked)
            children[0].props.Clicked()
    }, []);

    return (
        <div className={`${props.isVertical ? 'd-flex align-items-start' : ''} ${props.ContainerClass}`} onFocus={props.onFocus} >
            <ul className={`nav nav-tabs  shadow-sm ${props.isVertical ? 'flex-column' : props.Class}`} id="myTab" role="tablist">
                {children.map((child,index) =>
                    <li className="nav-item" role="presentation" key={child.props.id}>
                        <button className={`nav-link  ${(props.activeTab === child.props.id) || (!props.activeTab && index == 0) ? 'active' : ''} ${child.props.icon ? 'iconssc-' + child.props.icon : ''}  ${props.isVertical ? 'rounded-0 border-end-0 border' : '' }`} id="home-tab" data-bs-toggle="tab"
                            data-bs-target={`#${child.props.id}`} type="button" role="tab"
                            aria-controls="home-tab-pane" aria-selected="true"
                            onClick={() => selectedChangedHandler(child.props)} disabled={child.props.disabled}>{app.translate(child.props.label)}</button>
                    </li>
                )}
            </ul>
            {!props.noBody && <div className={`tab-content px-3 pb-2 pt-1 w-100 border border-1 ${props.isVertical ? '' : 'border-top-0'} ${props.noShadow ? 'border-bottom-0' : 'shadow-sm'} overflow-auto position-relative ${(props.activeTab === children[0].props.id && (props.isLocked || props.isCanceled)) ? 'bg-black bg-opacity-10' : 'bg-body-tertiary'}`} id="nav-tabContent" style={{ "maxHeight": "calc(100vh - 195px)" }} >
                {children.map((tab, index) =>
                    <Tab key={index} 
                        Class={`tab-pane fade ${(props.activeTab === tab.props.id) || (!props.activeTab && index == 0) ? ' show active' : ''}`}
                         {...tab.props} >
                </Tab>)
                }
                {props.activeTab === children[0].props.id && (props.isLocked || props.isCanceled) &&
                    <div className="position-absolute top-50 start-50 translate-middle  ">
                        <img src={`images/${props.isLocked ? 'lock' : 'cancel'}.png `} className="rounded" alt="..." />
                    </div>
                }
            </div>}
        </div>
    );
}

Tabs.propTypes = {
    isLocked: PropTypes.bool,
    isCanceled: PropTypes.bool,
    noBody: PropTypes.bool,
    noShadow: PropTypes.bool,
    Class: PropTypes.string,
    isVertical: PropTypes.bool
}

Tabs.defaultProps = {
    isLocked: false,
    isCanceled: false,
    noBody: false,
    noShadow: false,
    Class: " ",
}
