import React, { useState } from "react";
import * as app from "../../services/AppService";
import * as http from "../../services/http";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from '../../components/Button';
import Language from '../Language';
import { useSelector } from "react-redux"

export default function ShippingClientLayout(props) {
    const optionInfo = useSelector(store => store.data.options);
    const [model, setModel] = useState({
        openMenu: true,
        //menu: [
        //    { id: 1, name: 'Home' },
        //    { id: 2, name: 'RemRec' },
        //    { id: 3, name: 'RemtPayment' },
        //    { id: 4, name: 'Queries' },
        //]
    })

    function menuButtonClicked() {
        setModel(old => ({ ...old, openMenu: !old.openMenu }))
    }
    function Logout() {

    }
    return (
        <>
            <>
                <Navbar bg="white" expand="lg" className="sticky-top shadow-sm">
                    <Container fluid>

                        <button className="btn btn-light me-3 shadow-sm border-secondary border-1 text-secondary rounded-1"
                            onClick={menuButtonClicked} type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                            <span className="iconssc-menu "></span>
                        </button>

                        <Navbar.Brand className="bg-transparent shadow-none" >
                            <img
                                src={optionInfo.companyLogo.length === 0 ? "images/logo.png" : "data:image/jpeg;base64," + optionInfo.companyLogo}
                                width="100"
                                height="35"
                                alt="React Bootstrap logo"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <div className="vr mx-3"></div>
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll>

                                <Form className="d-flex">
                                    <Button Class="border-0 text-primary-emphasis" Disabled={true} Clicked={Logout}><span className="iconssc-search"></span></Button>
                                    <Form.Control
                                        type="search"
                                        placeholder="Search here"
                                        className="me-2 border-0 bg-body-tertiary"
                                        aria-label="Search"
                                    />
                                </Form>
                            </Nav>

                            <span className="text-primary-emphasis mx-2 iconssc-user"></span>

                            <Nav.Item>
                                <Nav.Link eventKey="link-1 " disabled>{app.options().userName}</Nav.Link>
                            </Nav.Item>

                            <span className="text-primary-emphasis mx-2 iconssc-bell"></span>

                            <Language />

                            <Button Class="outline-primary  text-primary-emphasis" Clicked={Logout}><span className="iconssc-logout"></span></Button>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>

            <main className="d-lg-flex flex-lg-nowrap w-100 overflow-auto">



                <div className="bd-sidebar sticky-lg-top" style={{ 'display': (model.openMenu ? 'block' : 'none') }}>

                    <div className="offcanvas offcanvas-start show sticky-lg-top" tabIndex="-1" id="bdSidebar" aria-labelledby="bdSidebarOffcanvasLabel">
                        <div className="offcanvas-body ">

                        </div>
                    </div>
                </div>

                <div className="card border-0 container-fluid">
                    <div className=" pt-2">
                        <nav aria-label="breadcrumb">
                            {/*<ol className="breadcrumb mb-0">*/}
                            {/*    {*/}
                            {/*        model.navigation.map((nav, index) => <li className="h5 text-primary-emphasis fw-bold breadcrumb-item" key={index}>{app.translate(nav)}</li>)*/}
                            {/*    }*/}
                            {/*</ol>*/}
                        </nav>
                        <nav className="breadcrumb-divider-none" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                {/*{*/}
                                {/*    model.subNavigtion.filter(item => item.parentId === model.id)*/}
                                {/*        .map((nav, index) =>*/}
                                {/*            <li className="breadcrumb-item" key={index} >*/}
                                {/*                <button type="button" onClick={() => openPath(nav)} className="btn btn-link px-1">*/}
                                {/*                    {app.translate(nav.name)}</button>*/}
                                {/*            </li>)*/}
                                {/*}*/}
                            </ol>
                        </nav>
                    </div>

                    <div>
                        {props.children}
                    </div>
                </div>
            </main>
        </>
    );
}
