import thunk from "redux-thunk"
import appDataReducer from "./data"
import showSpinner from "./spinner"
import msgDialog     from "./msgDialog"
import loginState from "./auth"

const redux = require("redux")
const { combineReducers, createStore, applyMiddleware } = redux
//var init =localStorage.store;
const rootReducer = combineReducers({
    data:appDataReducer,
    showSpinner,
    msgDialog,
    loginState,
})

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store