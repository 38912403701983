/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import * as app from "../services/AppService"
import "./Autocomplete.css"
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js'

function Autocomplete(props) {

    const defaulModel = {
        item: {
            Key: "0",
            Value: props.DefaultValue
        },
        searchItems: props.Options,
        cursor: -1,
        selected: false,
        elmWidth: 250,
        disabled: props.Disabled
    };

    const [model, setModel] = useState(defaulModel);

    //#region //للتحكم بعرض الخيارات اذا تغير عرض النافذة
    var setWindowSize = () =>
        setModel(old => ({ ...old, elmWidth: document.getElementById(props.ID ? props.ID : "auto" + props.Label).offsetWidth }));

    useEffect(() => {
        window.addEventListener('resize', setWindowSize)
        setWindowSize();
        return () => window.removeEventListener('resize', setWindowSize)
    }, [])

    useEffect(() => {
        let toggleBtn = document.getElementById(props.ID ? props.ID : "auto" + props.Label)
        let dropdownEl = new bootstrap.Dropdown(toggleBtn);        
                dropdownEl.hide();
        setModel(old => ({ ...old, disabled: props.Disabled }))
    }, [props.Disabled])
    //#endregion
    //لكي يتم اختيار القيمة الصحيحة اذا تاخر جلب الخيارات الى ما بعد اعطاء =قيمة للمودل
    useEffect(() => {
        let selectedItem = props.Options && !(String(model.item.Key) === "0" && props.OnEnterClicked) ? props.Options.find(item => String(item.Key) === String(model.item.Key)) : undefined;
        setModel(old => {
            return {
                ...old,
                item: selectedItem !== undefined ? selectedItem : old.item,
                searchItems: props.Options
            }
        })
    }, [props.Options]);
    //تغيير قيمة السترينغ المعروض داخل الحقل
    useEffect(() => {
        setModel(old => ({
            ...old,
            item: { ...old.item, Value: props.DefaultValue }
        }))
    }, [props.DefaultValue]);

    useEffect(() => {
        let selectedItem = undefined;
        try {
            selectedItem = props.Options.find(item => String(item.Key) === String(props.Model));
        } catch (e) {}
        if (selectedItem !== undefined) {
            selectItem(selectedItem)
        }
        else {
            selectItem({ Key: props.Model, Value: props.DefaultValue })
        }
    }, [props.Model]);

    function selectedItemChanged(item) {
        toggleDropdown(false);
        setModel(old => ({ ...old, cursor: -1 }));
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange(item.Key, item, props.Key, props.Options);
    }

    function toggleDropdown(show) {
        let toggleBtn = document.getElementById(props.ID ? props.ID : "auto" + props.Label)
        let dropdownEl = new bootstrap.Dropdown(toggleBtn);
        if (show)
            dropdownEl.show();
        else
            dropdownEl.hide();
    }

    function onInputFocus() {
        if (props.OnEnterClicked)
        toggleDropdown(true);
        setWindowSize();
        if (model.item.Value === "") 
            autocomplete(undefined,true);
        if (props.OnFocus && typeof (props.OnFocus) == "function")
            props.OnFocus();
    }

    function autocomplete(evt,dontToggle) {
        if (!dontToggle)
            toggleDropdown(true);
        let text = evt ? evt.target.value : props.DefaultValue;
        let item = { Value: text, Key: 0 };

        setModel((oldModel) => ({
            ...oldModel,
            item,
            selected: false,
            cursor: -1,
            searchItems: +props.Min > 0 && text.length < +props.Min ? [] : props.Options ? props.Options.filter(r => props.Filter(r, text)) : []
        }));
        if (props.OnSearch && typeof (props.OnSearch) == "function")
            props.OnSearch(text);
    }

    function hanldeKeydown(evt) {
        const { cursor, searchItems } = model;

        if (evt.keyCode === 38 && cursor > 0) {
            setModel((oldModel) => ({ ...oldModel, cursor: oldModel.cursor - 1 }));
            var list = document.getElementById("dropdown" +(props.ID ? props.ID :  props.Label));
            list.scrollTop = (cursor *32) -25;
        } else if (evt.keyCode === 40 && cursor < searchItems.length - 1) {
            var dropDownlist = document.getElementById("dropdown" + (props.ID ? props.ID : props.Label));
            dropDownlist.scrollTop = (cursor * 32) - 25;
            setModel((oldModel) => ({ ...oldModel, cursor: oldModel.cursor + 1 }));
        } else if (evt.keyCode === 13) {
            let selectedItem = searchItems[cursor];
            if (selectedItem !== undefined) {
                var item = selectedItem.Name ? { ...selectedItem, Value: selectedItem.Name } : selectedItem;
                selectItem(item)
                evt.preventDefault()
            } else if (props.OnEnterClicked && typeof (props.OnEnterClicked) == "function") {
                props.OnEnterClicked(model.item.Value);
                setModel(old => ({ ...defaulModel, elmWidth: old.elmWidth, item: { Key: "", Value: "" } }))
                evt.preventDefault()
            }
        }
    }

    function selectItem(selectedItem) {
        setModel(old => ({ ...old, item: selectedItem.Name ? { ...selectedItem, Value: selectedItem.Name } : selectedItem, selected: true }));
        selectedItemChanged(selectedItem)
    }

    function onblur() {
        toggleDropdown(false)
        if (props.OnBlur && typeof (props.OnBlur) == "function") 
            props.OnBlur(model.item);
    }

    return (
        <div className={props.containerClass}  >
            {props.Label && !props.IsTable && (
                <div className="d-flex justify-content-between">
                    <label className={`fw-semibold ${props.LabelClass}`}>{app.translate(props.Label)}</label>
                    {props.LabelElm !== undefined && <label className="fw-semibold text-danger me-2">{props.LabelElm}</label>}
                </div>
            )}
            <div className=" border rounded bg-light-subtle">
                <div className=" w-100 input-group">
                    <input type="text"
                        autoComplete="off"
                        id={props.ID ? props.ID : "auto" + props.Label}
                        key={props.Key}
                        value={model.item.Value}
                        className={`form-control form-control-sm rounded ${props.Required && !model.item.Value ? " is-invalid  border-1" : " border border-0 " + props.Class}`}
                        onChange={autocomplete}
                        onKeyDown={hanldeKeydown}
                        disabled={model.disabled}
                        onFocus={onInputFocus}
                        onBlur={onblur}
                        placeholder={app.translate(props.Placeholder)}
                        required={props.Required && !model.item.Value}
                        data-bs-toggle="dropdown"
                        onClick={() => { setTimeout(() => toggleDropdown(true), 200) }}
                    />
                    <ul id={"dropdown" + (props.ID ? props.ID : props.Label)} className="position-fixed z-index-5 dropdown-menu overflow-auto" tabIndex='1'
                        style={{ width: model.elmWidth }}>
                        {model.searchItems.map((item, idx) => (
                            <li key={idx} id={"li" + idx.toString()} style={{ "cursor": "pointer" }}
                                    onMouseDown={() => selectItem(item)}
                                    className={model.cursor === idx ? "dropdown-item active p-1 rounded-0" :
                                        "dropdown-item p-1 rounded-0"}>
                                    {item.Value}
                                
                            </li>
                        ))}
                    </ul>
                    {props.Icon &&
                        <button className="btn btn-primary border-0 p-0 px-2" type="button" disabled={!props.IconClicked} onClick={props.IconClicked}>
                            <i className={` iconssc-${props.Icon}`} />
                        </button>
                    }
                </div>
            </div>
        </div>

    );
}

Autocomplete.propTypes = {
    Label: PropTypes.string,
    LabelClass: PropTypes.string,
    Options: PropTypes.array.isRequired,
    Class: PropTypes.string,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    Disabled: PropTypes.bool,
    Required: PropTypes.bool,
    OnChange: PropTypes.func,
    Placeholder: PropTypes.string,
    Icon: PropTypes.string,
    OnEnterClicked: PropTypes.func,
    DefaultValue: PropTypes.string,
    containerClass: PropTypes.string,
    OnBlur: PropTypes.func,
    Min: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    OnSearch: PropTypes.func,
    OnFocus: PropTypes.func,
    Filter: PropTypes.func,
    ID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    IsTable: PropTypes.bool,

}

Autocomplete.defaultProps = {
    Class: " ",
    Icon: "",
    LabelClass: "text-primary-emphasis",
    DefaultValue: "",
    Default: 0,
    containerClass: "mt-1",
    Disabled: false,
    Required: false,
    Min: 0,
    Filter: (r, text) => (r.Value || '').toLowerCase().includes((text || '').toLowerCase()),
    IsTable: false,
}

export default Autocomplete;