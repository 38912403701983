import React from "react";
import PropTypes from "prop-types";
import { translate } from "../services/AppService";

function Password(props) {
    const [controle, setControle] = React.useState({
        hiddeStat: true
    });

    function handleChange(event) {
        const { value } = event.target;
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange(value, props.Key);
    }

    function SetEyeStat() {
        setControle((old) => {
            return {
                hiddeStat: !old.hiddeStat,
            };
        });
    }

    function hanldeKeydown(evt) {
        if (evt.keyCode === 13 && props.OnEnterClicked && typeof (props.OnEnterClicked) == "function")
            props.OnEnterClicked();
    }
    return (
        <div className="mt-2">
            <label className="form-label fw-semibold text-primary-emphasis">
                {translate(props.Label)}
            </label>

            <div className="input-group input-group-sm border rounded">
                <input
                    className={`form-control form-control-sm rounded ` + props.Class}
                    type={controle.hiddeStat ? "password" : "text"}
                    id={props.ID ? props.ID : "pass" + props.Label}
                    onChange={handleChange}
                    value={props.Model}
                    onKeyDown={hanldeKeydown}
                    placeholder={translate(props.placeholder)}
                />

                <button className="btn btn-sm rounded p-0 px-2" type="button" onClick={SetEyeStat}>
                    {controle.hiddeStat ? (
                        <i className="iconssc-eye fs-5 text-primary-emphasis"></i>
                    ) : (
                        <i className="iconssc-eye-hide fs-5 text-primary-emphasis"></i>
                    )}
                </button>
            </div>
        </div>
    );
}

Password.propTypes = {
    Model: PropTypes.string.isRequired,
    Class: PropTypes.string,
    Label: PropTypes.string,
    placeholder: PropTypes.string,
};
Password.defaultProps = {
    Class: " border-0",
    Label: "Password",
    placeholder: "password",
};

export default Password;
