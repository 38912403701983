import React, { useEffect, useState } from "react";
import { MultiSelect as MultiSelected } from "react-multi-select-component";
import * as app from "../services/AppService";
import PropTypes from "prop-types";

const MultiSelect = (props) => {
  const [selectedObj, setselectedObj] = useState([]);
  const [options, setOptions] = useState([]);

  const SelectValues = () => {
    var ArrV = [];
      ArrV = options.filter((item) => { if (props.value&& props.value.includes(item.value) )  return item})
    setselectedObj(ArrV); 
  }   

  useEffect(() => {
    SelectValues();
  },[props.value, options])
    
  const SelectOption = () => {
    var arr = [];
    arr = props.options.map((item) => {
      return { label: item.Value, value: item.Key };
    });
    setOptions(arr);
  }

  useEffect(() => {
    SelectOption();
  }, [props.options]);
  
  const handler = (selectedObjectsArray) => {
    props.onChange(selectedObjectsArray,selectedObjectsArray.map((i) => i.value));
  };

    var LablesValues = {
        selectAll: app.translate("SelectAll"),
        search: app.translate("Search"),
        selectSomeItems: app.translate("select"),
        allItemsAreSelected: app.translate("Don") + " " + app.translate("SelectAll")
    };

    return (

      <div className="mt-1">
      <label className={`${props.LabelClass}`}>{app.translate(props.Label)}</label>
      <MultiSelected
                options={options}
                value={selectedObj}
                onChange={handler}
                labelledBy="Selectmax"
                className={`shadow-sm rounded h-25 ${props.Class}`}
                disabled={props.Disabled}
                overrideStrings={LablesValues}
      />
    </div>
  );
};
MultiSelect.propTypes = {
    Label: PropTypes.string,
    LabelClass: PropTypes.string,
    Class: PropTypes.string
}
MultiSelect.defaultProps = {
    Class: " ",
    LabelClass: "fw-semibold text-primary-emphasis ",
    Disabled: false,
}
export default MultiSelect;
