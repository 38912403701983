import React from "react";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import * as app from "../../services/AppService.js";
import * as http from "../../services/http.js";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "../../components/Button";
import { useNavigate } from 'react-router';
import Language from "../Language";
import ColorMode from "../ColorMode"

export default function NavMenu(props) {
    let navigate = useNavigate();
    var Options = app.options();
    
  return (

      <Navbar className="shadow-sm " bg="primary-subtle" variant="dark" expand="lg">
          <Container>
              {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
              <div id="basic-navbar-nav" className="row w-100 mb-2 mt-2">

                  <div className="col-lg-10 col-10 fw-bold">
                      <Nav className="d-flex flex-row" variant="underline" defaultActiveKey={window.location.pathname} >

                          <ColorMode TitleClass="text-light-emphasis " />
                          <Language Class="fs-5" TitleClass="text-light-emphasis " />

                          <Nav.Item className="mx-3 align-self-center">
                              <Nav.Link as={Link} to="/" href="/">
                                  {app.translate("Main")}
                              </Nav.Link>
                          </Nav.Item>

                          {Options.Version_Kind != 7 && Options.Version_Kind != -1 && Options.HideButtonsInMainInterface == 0 && <Nav.Item className="mx-3 align-self-center">
                              <Nav.Link className="" as={Link} to="/TrackTransfer" href="/TrackTransfer">
                                  {app.translate("TrackTransfer")}
                              </Nav.Link>
                          </Nav.Item>}

                          {Options.Version_Kind != 7 && Options.Version_Kind != -1 && Options.HideButtonsInMainInterface == 0 && <Nav.Item className="mx-3 align-self-center">
                              <Nav.Link className="" as={Link} to="/OurAgents" href="/OurAgents">
                                  {app.translate("AllAgents")}
                              </Nav.Link>
                          </Nav.Item>}

                      </Nav>
                  </div>
         

                  <div className="col-lg-2 col-2 d-flex justify-content-center">
                      <Button Class="btn-light py-2 px-4 shadow-sm " Clicked={() => navigate('/Signin')} Label="LogIn" />
                  </div>

              </div>
          </Container>
 
      </Navbar>


   
   
    );
}
