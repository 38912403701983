import React, { Suspense } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { agentRouts, publicRoutes, clientRouts, shippingClientRouts } from './RouteList';
import { useSelector } from "react-redux"
import PublicLayout from "../layout/PublicLayout"
import UserLayout from "../layout/UserLayout"
import AgentLayout from "../layout/AgentLayout"
import ClientLayout from "../layout/ClientLayout"
import ShippingClientLayout from "../layout/ShippingClientLayout"

export default function AppRoutes() {
    const RoutesRenderer = routArray => routArray.map((RouteObj, index) =>
        <Route key={index} exact path={RouteObj.path + (RouteObj.routeParam ? "/:" + RouteObj.routeParam : "")}
            element={
                <Suspense fallback={<div>Loading...</div>}>
                    <RouteObj.element {...RouteObj.props} />
                </Suspense>} />
    );
    const userRoutes = useSelector(store => store.data.routs);
    return (
        <Routes>
            loginState.userType == -1 ?
            <Route element={<PublicRoute />} >{RoutesRenderer(publicRoutes)}</Route>
            : loginState.userType == 2 ?
            <Route element={<PrivateRoute />} >{RoutesRenderer(agentRouts)}</Route>
            :loginState.userType == 4 ?
            <Route element={<PrivateRoute />} >{RoutesRenderer(clientRouts)}</Route>
            :loginState.userType == 5 ?
            <Route element={<PrivateRoute />} >{RoutesRenderer(shippingClientRouts)}</Route>
            :
            <Route element={<PrivateRoute />} >{RoutesRenderer(userRoutes)}</Route>
        </Routes>
    );
}

function PrivateRoute() {
    let loginState = useSelector(store => store.loginState);
    return (
        loginState.userType == 4 ?//زبون شحن
            <ShippingClientLayout >
                <Outlet />
            </ShippingClientLayout>
            : loginState.userType == 3 ?//زبون عادي-حوالات
                <ClientLayout >
                    <Outlet />
                </ClientLayout>
                : loginState.userType == 2 ?//وكيل
                    <AgentLayout >
                        <Outlet />
                    </AgentLayout>
                    : loginState.userType == 0 || loginState.userType == 1 ?//مستخدم - موظف او مدير
                        <UserLayout >
                            <Outlet />
                        </UserLayout>
                        :
                        <Navigate to="/Signin" />
    )
}
function PublicRoute() {
    return (
        <div className="bg-body vh-100" >
            <PublicLayout />
            <Outlet />
        </div>
    );
}