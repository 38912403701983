import React, { useEffect, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2'
import PropTypes from "prop-types";
import * as app from "../services/AppService";
import ar from 'react-phone-input-2/lang/ar.json'
import tr from 'react-phone-input-2/lang/tr.json'
import { useSelector } from "react-redux"
//https://github.com/bl00mber/react-phone-input-2  documentation

function PhoneInput(props) {
    const defaultCountry = useSelector(store => store.data.options.FirstCountryCode)||"90";

    const [model, setModel] = useState({
        dialCode: "",
        number: "",
        country: props.Country
    });
    function clearNum(num) {
       return num && num.length > 0 && num[0] == "0" ? clearNum(num.substring(1)) : num;
    }

    useEffect(() => {
        let newVal = clearNum(props.Model)  
        setModel(old => ({ ...old, number: newVal ? newVal : model.dialCode ? model.dialCode : defaultCountry, }));
    }, [props.Model])

    useEffect(() => {
        setModel(old => ({ ...old, country: props.Country ? props.Country.toLowerCase() : "" }))
    }, [props.Country])

    function handler(value, data, event, formattedValue) {
        let number = value == data.dialCode ? "" : value;
        setModel(old => ({ ...old, number: value, dialCode: data.dialCode }))
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange(number, props.Key);
    }
    function blured(event, data) {
        const { value } = event.target;
        if (app.options().IsCentralBank === 1 && value.length !== data.format.length && value.length < 20) {
            setModel(old => {
                let number = model.dialCode ? model.dialCode : defaultCountry
                if (props.OnChange && typeof (props.OnChange) == "function")
                    props.OnChange(number, props.Key);
                return { ...old, number };
            });
        }
    }

    return (
        <div className="mt-1">
            <label className={`fw-semibold ${props.LabelClass}`}>{app.translate(props.Label)}</label>
            <ReactPhoneInput countryCodeEditable={false} enableSearch localization={app.translate("Lang") == "ar" ? ar : app.translate("Lang") == "tr" ? tr : ""}
                country={model.country} value={model.number} name="phone" containerClass="h-50 "
                masks={{ iq: '(...) ...-..-..', sy: '(...) ...-...', lb: '(..) ...-...' }}
                buttonClass=" rounded-start " inputClass="w-100 h-50 rounded-2 bg-body-tertiary"
                inputProps={{ style: { direction: 'ltr' }, id: props.ID ? props.ID : "phon" + props.Label }}
                disabled={props.Disabled}
                onBlur={blured}
                onChange={handler} />
        </div>

    );
}

PhoneInput.propTypes = {
    Label: PropTypes.string,
    LabelClass: PropTypes.string,
    OnChange: PropTypes.func,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    Disabled: PropTypes.bool
}
PhoneInput.defaultProps = {
    LabelClass: "text-primary-emphasis ",
}
export default PhoneInput;