import React from 'react';
import PropTypes from "prop-types";
import * as app from "../services/AppService.js";

function Image(props) {  

    function handler() {
        if (props.Clicked && typeof (props.Clicked) == "function")
            props.Clicked();
    }
    return (
        <img src={props.src} className={props.Class} onClick={handler}
            disabled={props.Disabled} alt={props.Alt} placeholder={props.Placeholder} />
    );
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    Class: PropTypes.string,
    Disabled: PropTypes.bool,
    Alt:PropTypes.string,
    Clicked:PropTypes.func,
    Placeholder:PropTypes.string,
}
Image.defaultProps = { 
    Class: "",
    Disabled:false,
    Alt:"Image",
    Placeholder:"",
}
export default Image;
