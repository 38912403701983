import React from 'react';
import { useSelector } from "react-redux"
import * as app from "../services/AppService"
import Version from "../utils/version";
const EnStyle = React.lazy(() => import('../utils/styles/BestRate'));

export default function ApiLayout() {
    const optionInfo = useSelector(store => store.data.options);

    return (
        <div className="card  rounded-0 min-vh-100" style={{ background: "#1a1a19" }}>
            <EnStyle />
            <div className="row m-0">
                <div className="col-6 align-self-center card-body ">
                    <div className="d-flex justify-content-center ">
                        <div>
                            <h2 className="fw-bold text-light display-7">
                                Best Rate API
                            </h2>

                            <h2 className="fw-bold text-light display-7">
                                {optionInfo.companyName}
                            </h2>

                            <p className="text-secondary">
                                {optionInfo.Header4}
                            </p>

                            <h4 className="mt-5 fw-bold text-light">{app.translate("ContactUs")}.</h4>
                            <h6 className="text-secondary">{optionInfo.Header2}</h6>
                            <h6 className="text-secondary">{optionInfo.Header3}</h6>
                            <img
                                className="mt-5"
                                src={optionInfo.companyLogo.length === 0 ? "Images/logo.png" : "data:image/jpeg;base64," + optionInfo.companyLogo}
                                width="200"
                                height="65"
                                alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-6 p-0 d-none d-lg-flex justify-content-end">
                    <img alt="" src="Images/API.png"
                        className="" />
                </div>

                <div className="col-12 card-footer">
                    <div className="row my-2">

                        <div className="col-1">
                            <Version />
                        </div>

                        <div className="col-auto">
                            <span className="text-light me-2">Api Version:</span>
                            <span className="text-secondary">13.0.5</span>
                        </div>

                        <div className="col-auto">
                            <span className="text-light me-2">Pdf Version:</span>
                            <span className="text-secondary">13.0.5</span>
                        </div>

                        <div className="col-auto">
                            <span className="text-light me-2">2023 -</span>
                            <a href="https://www.salahsoft.com/" className="text-secondary">SalahSoft</a>
                        </div>
                    </div>
                </div>

            </div>
 



        </div>
    );
}