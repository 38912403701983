import React, { ReactNode } from "react";
import * as app from "../services/AppService";
import PropTypes from "prop-types";
interface CheckboxProps {
    OnChange: Function, Model: boolean,  Disabled: boolean,  Class: string, Label: string,
    Name: string, Key: string, checkClass: string, IsTable: boolean
}

export default function Checkbox({ OnChange, Model, Disabled, Class, Label, Name, Key, checkClass, IsTable }:
    CheckboxProps): ReactNode {
    function handler(event: { target: { checked: boolean } }) {
        const { checked } = event.target;
        if (OnChange && typeof OnChange == "function")
            OnChange(checked, Key);
    }
    let label = (<label htmlFor={Name} className={"ms-2 form-check-label text-primary-emphasis " + Class}>
        {app.translate(Label)}
    </label>);
    let input = (<input
        type="checkbox"
        name={Name}
        disabled={Disabled}
        className="form-check-input"
        onChange={handler}
        checked={Model}
    />)
    let IsAr = app.getCookie("language", "en") == "ar";
    return (
        <div className={IsTable ? "" : `form-check ${checkClass}`}>
            {IsAr ? input : label}
            {IsAr ? label : input}
        </div>
    );
}

Checkbox.propTypes = {
    OnChange: PropTypes.func,
    Name: PropTypes.string,
    Disabled: PropTypes.bool,
    Class: PropTypes.string,
    checkClass: PropTypes.string,
    Label: PropTypes.string,
    Model: PropTypes.bool,
    Clicked: PropTypes.func,
    IsTable: PropTypes.bool,
    Key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

Checkbox.defaultProps = {
    Class: "",
    checkClass: "mt-1",
    Name: "chk",
    Label: "",
    IsTable: false,
    Disabled: false,
};
