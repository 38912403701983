import React from 'react';
import PropTypes from "prop-types";
import * as app from "../services/AppService";

function Number(props) {

    function handler(event) {
        const { value } = event.target;
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange(value, props.Key);
    }
    let input = (<input type="number"
        id={props.ID ? props.ID : "num" + props.Label}
        key={props.Key}
        className={`form-control form-control-sm border rounded ${props.IsTable ? " border-1  text-center " + props.TableClass : props.Class}`}
        disabled={props.Disabled}
        onChange={handler} value={props.Model}
        onBlur={props.OnBlur}
        placeholder={app.translate(props.Placeholder)}
        min={props.minValue}
        max={props.maxValue}
    />)
    return (props.IsTable ? input :
        <div className="mt-1">
            <label className={`${props.LabelClass}`}>{app.translate(props.Label)}</label>
            <div className={`input-group rounded  ${props.ContainerClass}`}>
                {input}
                {props.Icon &&
                    <button className="btn btn-light" type="button" disabled>
                        <i className={"text-primary-emphasis " + `icofont-${props.Icon}`} />
                    </button>
                }
            </div>
        </div>
    );
}

Number.propTypes = {
    Label: PropTypes.string,
    Class: PropTypes.string,
    Disabled: PropTypes.bool,
    Type: PropTypes.string,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    Placeholder: PropTypes.string,
    OnChange: PropTypes.func,
    Key: PropTypes.string,
    IsTable: PropTypes.bool,
    OnBlur: PropTypes.func,
    minValue: PropTypes.number

}
Number.defaultProps = {
    Class: " ",
    Placeholder: "",
    LabelClass: " fw-semibold text-primary-emphasis ",
    ContainerClass: " ",
    Disabled: false,
    Key: "input",
    IsTable: false,
    minValue: 0
}
export default Number;