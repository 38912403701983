import React, { useState } from 'react';
import { useEffect } from 'react';
import * as http from "../services/http";

export default function Version() {

    var [version, setVersion] = useState("");
    useEffect(() => {
        http.get("/Login/GetVersion", {}, res => {
            setVersion(res.AdditionalData);
        });
    }, [])

    return (
        <p className='text-danger text-center fw-bold mt-3' style={{ 'fontSize': '14px' }}>{version}</p>
    );
}