export function hideSpinner() {
    return {
        type: "HIDE_SPINNER"
    }
}
export function showSpinner() {
    return {
        type: "SHOW_SPINNER"
    }
}

export default function ShowLoadingReducer(show =  false, action) {
    switch (action.type) {
        case "SHOW_SPINNER":
            return true
        case "HIDE_SPINNER":
            return false
        default:
            return show
    }
}
