export function hideMsgDialog() {
    return {
        type: "HIDE_DIALOG"
    }
}

export function showMsgDialog(Body, Title, OnClose, Ok, CloseLabel, ExtraButtons = [], ConfirmMsg = "", Label, DefValue) {

    return {
        type: "SHOW_DIALOG",
        diaolgData: {
            Title,
            Body,
            OnClose,
            Show: true,
            Ok,
            CloseLabel,
            ExtraButtons,
            ConfirmMsg,
            Label,
            DefValue
        }
    }
}

const initialState = {
    Title: "",
    Body: "",
    OnClose: null,
    Show: false,
    Ok: null,
    CloseLabel: "Close",
    ExtraButtons: [],
    ConfirmMsg: "",
    Label: ""
}

export default function msgDialogReducer(msgDialog = initialState, action) {
    switch (action.type) {
        case "SHOW_DIALOG":
            return action.diaolgData
        case "HIDE_DIALOG":
            return { ...msgDialog, Show: false }
        default:
            return msgDialog
    }
}
