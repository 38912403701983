/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as app from "../../services/AppService";
import "./Table.css";
import TablePagination from "./TablePagination.js";
import Input from "../Input";

export default function Table(props) {
    //---- State ----//
    const [model, setModel] = useState({
        totalCount: 0,
        totalPages: 0,
        pageSize: 0,
        pageNumber: 1,
        possiblePageSizes: [
            { Key: 10, Value: 10 },
            { Key: 50, Value: 50 },
            { Key: 100, Value: 100 },
            { Key: 1000, Value: 1000 },
        ],
        pageData: [],
        allTableData: props.Data,
        desc: false
    });
    const [filterModel, setFilterModel] = useState(props.filterablCols ? props.filterablCols.reduce((obj, currentProp) => {
        obj[currentProp] = "";
        return obj;
    }, {}) : "");
    //---- Change Page Index ----//
    function PageIndexHandle(pageNumber) {
        if (pageNumber <= model.totalPages && pageNumber > 0)
            setModel((old) => ({ ...old, pageNumber }));
    }

    //---- Change Page Size ----//     
    function PageSizeHandle(newSize) {
        setModel((old) => ({
            ...old,
            pageSize: Number(newSize),
            pageNumber: 1,
            totalPages: Math.ceil(model.totalCount / newSize),
        }));
    }
    function RowClickedHandler(row, rowIndex) {
        if (props.RowClicked && typeof props.RowClicked == "function")
            props.RowClicked(row, rowIndex);
    }
    //---- Update data for current page index ----//
    const applyFilters = (data) => {
        let filtered = [...data];
        for (let key in filterModel) {
            if (filterModel[key]) {
                var rowIndex = props.Cols.indexOf(key);
                filtered = filtered.filter(item =>
                    item[props.Rows[rowIndex]] ? String(item[props.Rows[rowIndex]]).toLowerCase().includes(filterModel[key].toLowerCase()) : false
                );
            }
        }
        return filtered;
    };
    function UpdatePageData(allTableData) {
        let afterFilterData = applyFilters(allTableData);
        if (props.HidePagination) {
            setModel((old) => ({ ...old, pageData: [...afterFilterData] }));
            return;
        }
        const pageData = [];
        let remainingCount = afterFilterData.length - ((model.pageNumber - 1) * model.pageSize);
        let startIndex = (model.pageNumber - 1) * model.pageSize;

        let endIndex = remainingCount <= model.pageSize
            ? startIndex + remainingCount
            : startIndex + model.pageSize;

        for (var i = startIndex; i < endIndex; i++)
            pageData.push(afterFilterData[i]);
        setModel((old) => ({ ...old, pageData }));
    }
    function RowColor(row, rowIndex) {
        if (props.RowColor && typeof props.RowColor == "function")
            return props.RowColor(row, rowIndex);
    }
    function RowClass(row, rowIndex) {
        if (props.RowClass && typeof props.RowClass == "function") {
            const clas = props.RowClass(row, rowIndex);
            return "table-" + clas + " text-white ";
        }
    }
    //---- Use Effect to load data for first time  ----//

    React.useEffect(() => {
        let totalCount = props.Data.length;
        let totalPages = totalCount < 10 ? 1 : Math.ceil(totalCount / 10);
        setModel((old) => ({
            ...old,
            allTableData: props.Data,
            totalCount,
            pageSize: 10,
            pageNumber: 1,
            totalPages
        }));
    }, [props.Data]);//, props.Data.length

    //---- Use Effect to update data when changed  ----//
    React.useEffect(() => {
        UpdatePageData(model.allTableData);
    }, [model.pageNumber, model.pageSize, model.allTableData]);

    let rowCountElement = (
        <div className="col-auto mx-2 pt-1 ms-auto text-center bg-light-subtle border border-1 rounded d-none d-sm-block">
            <span className="fw-normal px-2">
                {app.translate("CountRecord")} :
                <small className="fw-bold">
                    {props.Data && " " + props.Data.length}
                </small>
            </span>
        </div>);

    function order(index) {
        setModel(old => {
            let allTableData = props.Data.sort((a, b) => '' + a[props.Rows[index]] > '' + b[props.Rows[index]] ? old.desc ? 1 : -1 : old.desc ? -1 : 1)
            UpdatePageData(allTableData);
            return { ...old, allTableData, desc: !old.desc }
        })
    }
    //---- RETURN ----//
    useEffect(() => {
        UpdatePageData(model.allTableData)
    }, [filterModel])
    return (
        <>
            <div
                className={props.ContainerClass && (" overflow-auto ") + props.ContainerClass}>
                <table className={`table table-sm table-bordered border-dark-subtle align-middle table-hover m-0 ${props.Class}`}
                    aria-labelledby="tabelLabel"
                    ref={props.tableRef}
                >
                    <thead className="position-sticky " style={{ cursor: "pointer" }}>
                        <tr className={props.TheadTrClass}>
                            {props.Cols.filter((c, i) => !(typeof c == "function" && c(i).props && app.getBoolean(c(i).props.hide))).map((col, index) => {
                                let IsFilterable = props.filterablCols && props.filterablCols.includes(col);
                                return (
                                    <th key={index} scope="col-2" onClick={() => !IsFilterable&&order(index)}
                                        className={(typeof props.Cols[index] == "function" || IsFilterable ? "" : "iconssc-down-arrow ") + (model.pageData.length > 0 && typeof props.Rows[index] == "function" &&
                                            ((props.Rows[index](model.pageData[0]).type && props.Rows[index](model.pageData[0]).type.name === 'Button') ||
                                                (props.Rows[index](model.pageData[0]).props && props.Rows[index](model.pageData[0]).props.children &&
                                                    props.Rows[index](model.pageData[0]).props.children[0]
                                                    && props.Rows[index](model.pageData[0]).props.children[0].type
                                                    && (props.Rows[index](model.pageData[0]).props.children[0].type.name === 'Button' || props.Rows[index](model.pageData[0]).props.children[0].type.name === 'DropdownButton')))
                                            ? `text-center position-sticky end-0 text-light  bg-${props.ThBgColor}-subtle ` : `text-center text-light fw-bold bg-${props.ThBgColor}-subtle`)}>
                                        {typeof col == "function" ? col(index) : IsFilterable ?
                                            <Input Model={filterModel[col]} Placeholder={app.translate(col)} IsTable
                                                OnChange={val => setFilterModel(old => ({ ...old, [col]: val }))} /> :
                                            app.translate(col)}
                                    </th>
                                )
                            })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {model.pageData.length === 0 && <tr><td colSpan="100" className="table-active text-center fw-bold">{app.translate("EmptyTable")}</td></tr>}
                        {model.pageData &&
                            model.pageData.map((row, rowIndex) =>
                                <tr
                                    key={rowIndex}
                                    className={"fw-lighter text-black rounded text-center " + RowClass(row, rowIndex)}
                                    onClick={() => RowClickedHandler(row, rowIndex)}>

                                    {props.Rows &&
                                        props.Rows.filter(r => !(typeof r == "function" && r(row).props && app.getBoolean(r(row).props.hide))).map((name, Colindex) => {
                                            return (typeof name == "function" && name(row).type === 'td' ? name(row, Colindex + '|' + rowIndex) :
                                                <td key={Colindex + '|' + rowIndex}
                                                    style={{ background: RowColor(row, rowIndex) }}
                                                    className={
                                                        (typeof name == "function" && name(row).props && (name(row).props.Table || name(row).props.table === "true" || name(row).props.IsTable)) ||
                                                            (typeof name == "function" && name(row).props && name(row).props.children && name(row).props.children[0].props && name(row).props.children[0].props.Table)
                                                            ? "text-center position-sticky end-0 bg-body-tertiary  " :
                                                            typeof name == "function" && name(row).props && name(row).props.tableClass ? name(row).props.tableClass : "text-center fw-normal"}>
                                                    {typeof name == "function" ? name(row, rowIndex, Colindex) : row[name]}
                                                </td>
                                            )
                                        })}

                                    {props.TableElements &&
                                        props.TableElements.map((name, ind) => (
                                            <td key={ind + '|' + rowIndex} className="text-center fw-normal ">
                                                <Input IsTable Model={row[name]} Key={name}
                                                    TableClass={props.ElementClass && typeof props.ElementClass == "function" ? props.ElementClass(name, row, ind) : props.ElementClass}
                                                    Disabled={props.TableElementsDisable && typeof props.TableElementsDisable == "function" ? props.TableElementsDisable(name, row, ind) : props.TableElementsDisable}                                                    
                                                    OnChange={(value, propName) => { props.OnTableElmChanged && props.OnTableElmChanged(row[props.Key], value, propName) }} />
                                            </td>
                                        ))}
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
            {!props.HidePagination && (
                <TablePagination
                    model={model}
                    PageIndexHandle={PageIndexHandle}
                    PageSizeHandle={PageSizeHandle}
                    HideTotalCount={props.HideTotalCount}
                    rowCountElement={rowCountElement}
                    Count={props.Data && " " + props.Data.length}
                />
            )}
            {!props.HideTotalCount && props.HidePagination && (

                <div className="row g-0 my-2">
                    {rowCountElement}
                </div>
            )}
        </>
    );
}

Table.propTypes = {
    Cols: PropTypes.array.isRequired,
    Rows: PropTypes.array.isRequired,
    Data: PropTypes.array.isRequired,
    Key: PropTypes.string,
    Class: PropTypes.string,
    //Buttons:
    TableElements: PropTypes.array,
    OnTableElmChanged:PropTypes.func,
    TheadTrClass: PropTypes.string,
    ThBgColor: PropTypes.string,
    ElementClass: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    RowClicked: PropTypes.func,
    HideTotalCount: PropTypes.bool,
    HidePagination: PropTypes.bool,
    RowColor: PropTypes.func,
    TableElementsDisable:PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func
    ]),
};
Table.defaultProps = {
    Class: " ",
    ContainerClass: "tableWrap-md ",
    
    //Buttons: [],
    TheadTrClass: "text-light bg-primary-subtle ",
    ThBgColor:"primary",
    ElementClass:"",
    HideTotalCount: false,
    HidePagination: false,
    TableElementsDisable:false,
    RowColor: null
};
