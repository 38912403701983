import React from 'react';
import PropTypes from "prop-types";
import * as app from "../services/AppService"

function Row(props) {
    let Childs = ((Array.isArray(props.children) ? props.children : [props.children]).filter(comp => typeof (comp) == "object")).filter(c => !app.getBoolean(c.props.hide));
    return (app.getBoolean(props.hide) ? "" :
        <div className={`row ${props.RowClass}`} key={props.RowKey}>
            {
                Childs.map((child, index) =>
                    <div key={index} className={`col-lg${child.props && child.props.colsize ? "-" + child.props.colsize : props.colssize ? "-" + props.colssize : ""} col-md-{Childs.length!=1?"6":"12"} col-12 ${child.props && child.props.colclass ? child.props.colclass : props.colclass ? props.colclass: ''}`}>
                    {child}
                </div>)   
            }
        </div>
    );
}

Row.propTypes = {
    RowClass: PropTypes.string,
    RowKey: PropTypes.string, 
    colclass: PropTypes.string,  
    colsize: PropTypes.string,
    hide: PropTypes.bool,
}

Row.defaultProps = {
    RowClass: "",
    RowKey:"",
    colclass: "",
    colsize: "",
    hide: false
}

export default Row;
