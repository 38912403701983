import React from "react";
import PropTypes from "prop-types"
import * as app from "../services/AppService";
import * as http from "../services/http";
import NavDropdown from "react-bootstrap/NavDropdown";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function Language(props) {

     
    async function changeLang(lang) {
        http.get(`/LogIn/SetLang?lang=${lang}`, {}, () => {
            app.setCookie("language", lang, 365);
            window.location.reload()
        });
    }
     
    return (

        <OverlayTrigger key={'Langbottom'} placement='bottom'
            overlay={
                <Tooltip style={{ position: "fixed" }} id={`tooltip-bottom`}>
                    {app.translate("Language")}
                </Tooltip>
            }>
            <NavDropdown className={`fw-light text-center mx-1 ${props.Class}`}
                title={<span className={`iconssc-language ${props.TitleClass}`}></span>} id="nav-dropdown" align="end" >
                <NavDropdown.Item onClick={() => changeLang("ar")}>
                    <img className="mx-3" src="https://cdn-icons-png.flaticon.com/32/206/206719.png" alt="" />
                    <span className="mx-1">العربية</span>
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => changeLang("en")} >
                    <img className="mx-3" src="https://cdn-icons-png.flaticon.com/32/206/206626.png" alt="" />
                    <span>English</span>
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => changeLang("tr")}>
                    <img className="mx-3" src="https://cdn-icons-png.flaticon.com/32/206/206634.png" alt="" />
                    <span>Turkish</span>
                </NavDropdown.Item>

            </NavDropdown>
        </OverlayTrigger>


    );
}

Language.propTypes = {
    Class: PropTypes.string,
    TitleClass: PropTypes.string,
}

Language.defaultProps = {
    Class: "mt-1 fs-6 ",
    TitleClass: "text-primary-emphasis "
}
