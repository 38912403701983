/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import * as app from "./services/AppService.js";
import Spinner from "./utils/Spinner";
import MsgDialog from "./components/PublicDialogs/MsgDialog";
import AppRoutes from "./routes/index"
import { reload } from "./store/auth"
import { useSelector, useDispatch } from "react-redux"
import ApiLayout from "./layout/ApiLayout"
import SmallSizeLayout from "./layout/SmallSizeLayout"

const ArStyle = React.lazy(() => import("./utils/RtlTheme"));
const EnStyle = React.lazy(() => import('./utils/LtrTheme'));

export default function App() {

    let navigate = useNavigate();
    const store = useSelector(store => store);
    const path = useLocation().pathname;

    const [state, setState] = useState({ loading: true, OnlyAPI: false, options: { winStyle: "bestrate" } });

    let IsAr = app.getCookie("language", "en") === "ar";

    useEffect(() => {
        if (path === "/Signin" && [0, 1].includes(store.loginState.userType))
            navigate("/UserDashboard");
        if (path === "/Signin" && +store.loginState.userType === 2)
            navigate("/AgentDashboard");
    }, [path])

    const dispatch = useDispatch()
    useEffect(() => {
        let IsLogout = ["/OurAgents", "/TrackTransfer", "/"].includes(window.location.pathname)
        dispatch(reload((res) => {
            if (!res.IsLoged && !IsLogout)
                navigate("/Signin");
            setState({ loading: false, OnlyAPI: res.OnlyAPI, options: res.IsLoged ? res.User.Options : res.Options })
        }, IsLogout));
        try {
            if (Notification && Notification.permission !== 'granted')
                Notification.requestPermission();
        } catch (e) {

        }
    }, []);
    return (
        state.loading ? <Spinner Loading={true} /> : state.OnlyAPI ?
            <ApiLayout /> :
            <>
                <div className="d-none d-md-block" style={{ direction: app.translate("Lang") === "ar" ? "rtl" : "ltr" }} data-bs-theme={app.getCookie("colorMode", window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light")}>

                    <title>{state.options.companyName}</title>
                    <React.Suspense fallback={<Spinner Loading={true} />}>
                        {IsAr && <ArStyle Style={state.options.winStyle} />}
                        {!IsAr && <EnStyle Style={state.options.winStyle} />}
                    </React.Suspense>
                    <Spinner Loading={store.showSpinner} />
                    <MsgDialog {...store.msgDialog} />
                    <AppRoutes />
                </div>

                <SmallSizeLayout /> 
            </>
    );
}