import React from "react";
import NavMenu from "./NavMenu";

export default function PublicLayout(props) {
    return (
        <div>
                <NavMenu />

            </div>
  );
}
