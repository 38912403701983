import store from "../store"
import * as app from "./AppService"

export function SetModel(data, model) {

    model.ID = data.ID;
    model.FirstName = data.FirstName;
    model.F_Name = data.F_Name;
    model.M_Name = data.M_Name;
    model.L_Name = data.L_Name;
    model.LastName = data.LastName;
    model.Identity_Kind = data.Identity_Kind;
    model.Identity_No = data.Identity_No;
    model.PlaceOfBirth = data.PlaceOfBirth;
    model.BirthdayDate = data.BirthdayDate;
    model.Expiration_Date = data.Expiration_Date;
    model.Sex = data.Sex == 0 ? false : true;
    model.Card_Address = data.Card_Address;
    model.PhoneCountryCode = data.PhoneCountryCode;
    model.Card_Phone = data.Card_Phone;
    model.Nationality_id = data.Nationality_id;
    if (model.L_Name && model.L_Name != "") model.FirstName = data.F_Name + " " + data.M_Name + " " + data.L_Name;
    model.BranchID = data.BranchID;
    model.Card_Kind = data.Card_Kind;
    model.Card_Mob = data.Card_Mob;
    model.CustomerType = data.CustomerType;
    model.IsBlackList = data.IsBlackList;
    model.MotherName = data.MotherName;
    model.UserID = data.UserID;
    //model.PhoneNumber = data.PhoneNumber;
};

function formattedDate(jsonDate) {
    if (!jsonDate) {
        return app.today();
    }
    if (jsonDate.getMonth != null) {
        return jsonDate;
    }
    return new Date(jsonDate.substring(
        jsonDate.lastIndexOf("(") + 1,
        jsonDate.lastIndexOf(")")
    ) * 1);
};

export function GetDefault(name) {
    return {
        ID: 0,
        FirstName: name ? name : '',
        F_Name: '',
        M_Name: '',
        L_Name: '',
        LastName: '',
        Sex: false,
        //PhoneNumber: '',
        Card_Phone: '',
        MotherName: '',
        Expiration_Date: app.today(-180),
        PlaceOfBirth: '',
        BirthdayDate: app.today(-365 * 15),
        Identity_Kind: 1,
        Identity_No: '',
        Card_Address: '',
        Nationality_id: 1,
        BranchID: 1,
        Card_Kind: 0,
        Card_Mob: "",
        CustomerType: false,
        IssuedBy: "",
        IssuedDate: app.today(),
        Card_Jop: '',
        Note: "",
        SourceIncome: 0,
        Annual_Income: 0,
        Currency_Id: 1,
        Living_Card_No: "",
        Living_Card_Issue_Date: app.today(),
        Living_Card_Issuer: "",
        Identity_Asylum_No: "",
        Identity_Asylum_Issue_Date: app.today(),
        Identity_Asylum_Issuer: "",
        AddressCountry:0,
        AddressCity:0,
        AddressArea:0,
        Address_District: "",
        AddressStreet: "",
        Address_Det: "",
    };
};

export function SetCard(model) {
    var Cardmodel = {
        ID: model.ID,
        FirstName: model.FirstName,
        F_Name: model.F_Name,
        M_Name: model.M_Name,
        L_Name: model.L_Name,
        LastName: model.LastName,
        Sex: model.Sex,
        Card_Phone: model.Card_Phone,
        PhoneCountryCode: model.PhoneCountryCode,
        PhoneNumber: "",
        MotherName: model.MotherName,
        Expiration_Date: model.Expiration_Date,
        PlaceOfBirth: model.PlaceOfBirth,
        BirthdayDate: model.Expiration_Date,
        Identity_No: model.Identity_No,
        Identity_Kind: model.Identity_Kind,
        BankAcc_No: model.BankAcc_No,
        Nationality_id: model.Nationality_id,
        Card_Address: model.Card_Address,
        BranchID: model.BranchID,
        Card_Kind: model.Card_Kind,
        Card_Mob: model.Card_Mob,
        CustomerType: model.CustomerType,
        IssuedBy: model.IssuedBy,
        IssuedDate: model.IssuedDate,
        Card_Jop: model.Card_Jop,
        Note: model.Note,
        SourceIncome: model.SourceIncome,
        Annual_Income: model.Annual_Income,
        Currency_Id: model.Currency_Id,
        Living_Card_No: model.Living_Card_No,
        Living_Card_Issue_Date: new Date(formattedDate(model.Living_Card_Issue_Date, "MM/DD/YYYY")),
        Living_Card_Issuer: model.Living_Card_Issuer,
        Identity_Asylum_No: model.Identity_Asylum_No,
        Identity_Asylum_Issue_Date: new Date(formattedDate(model.Identity_Asylum_Issue_Date, "MM/DD/YYYY")),
        Identity_Asylum_Issuer: model.Identity_Asylum_Issuer,
        UserID: model.UserID,
        AddressCountry: model.AddressCountry,
        AddressCity: model.AddressCity,
        AddressArea: model.AddressArea,
        Address_District: model.Address_District,
        AddressStreet: model.AddressStreet,
        Address_Det: model.Address_Det,
        Document_Image: model.Document_Image
    };

    model.ID = 0;
    model.FirstName = '';
    model.F_Name = '';
    model.M_Name = '';
    model.L_Name = '';
    model.LastName = '';
    model.Sex = false;
    model.PhoneCountryCode = '';
    model.PhoneNumber = '';
    model.Card_Phone = "";
    model.MotherName = '';
    model.Expiration_Date = app.today(-180);
    model.PlaceOfBirth = '';
    model.BirthdayDate = app.today(-365 * 15);
    model.Identity_Kind = 1;
    model.Identity_No = '';
    model.Card_Address = '';
    model.Nationality_id = 0;
    model.BranchID = 1;
    model.Card_Kind = 0;
    model.Card_Mob = "";
    model.CustomerType = false;
    model.UserID = 0;
    model.IssuedBy = "";
    model.IssuedDate = app.today(-1);
    model.Card_Jop = 0;
    model.Note = "";
    model.SourceIncome = 0;
    model.Annual_Income = 0;
    model.Currency_Id = 0;
    model.Living_Card_No = "";
    model.Living_Card_Issue_Date = app.today();
    model.Living_Card_Issuer = "";
    model.Identity_Asylum_No = "";
    model.Identity_Asylum_Issue_Date = app.today();
    model.Identity_Asylum_Issuer = "";

    model.AddressCountry = 0;
    model.AddressCity = 0;
    model.AddressArea = 0;
    model.AddressStreet = "";
    model.Address_District = "";
    model.Address_Det = "";
    return Cardmodel;
};

export function GetCardFields() {
    var obj = { Show: false, Required: false }
    return {
        Address:obj,
        BirthdayDate:obj,
        PlaceOfBirth:obj,
        IdentificationKind:obj,
        IdentificationNumber:obj,
        MotherName:obj,
        DateIssue:obj,
        IssuedFrom:obj,
        DataExpir:obj,
        SourceIncome:obj,
        MonthlyIncome: obj,
        Currency: obj,
        Work: obj,
        WorkStatement: obj,
        HousingCardNo: obj,
        HouseDateIssued: obj,
        HouseIssuer: obj,
        AsylumIdentityNo: obj,
        AsylumDateIssued: obj,
        AsylumIssuer: obj,
    }
}
