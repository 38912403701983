import React, { useEffect, useState, ReactNode } from "react";
import PropTypes from "prop-types";
import * as app from "../services/AppService";
interface CurrencyInputProps {
    ID: string, OnChange: Function, Model: String, Disabled: boolean, Class: string, Label: string, Key: string, IsTable: boolean,
    CurrencyID: string, OnBlur: Function, LabelClass: String, ContainerClass: String, Icon: String, TableClass: String
}
function CurrencyInput({ ID, OnChange, Model, Disabled, Class, Label, Key, IsTable, CurrencyID, OnBlur, LabelClass, ContainerClass, Icon, TableClass }:
    CurrencyInputProps): ReactNode {
    const [model, setModel] = useState({
        elmntVal: "0",
        isfocused: false
    })

    useEffect(() => {
        if (!model.isfocused)
            setModel(old => ({ ...old, elmntVal: old.isfocused ? format(Model) : app.roundNumber(Model, CurrencyID, true) }))
    }, [Model, CurrencyID, model.isfocused]);


    function handler(event: { target: { value: string } }) {
        const { value } = event.target;
        if (OnChange && typeof (OnChange) == "function") {
            OnChange((value + '').replace(/,/g, ''));
        }
        let elmntVal = format(value);
        setModel(old => ({
            ...old,
            elmntVal
        }));
    }

    function format(value:String) {
        var arr = (value).split('.')
        return arr && arr.length > 1 && arr[1] === '' ?
            (+(arr[0].replace(/,/g, ''))).toLocaleString('en-US') + "."
            : (+(arr[0].replace(/,/g, ''))).toLocaleString('en-US') + (arr.length < 2 ? "" : "." + arr[1])
    }

    function hanldeKeydown(evt: { keyCode: number, preventDefault: Function }) {
        if (![96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 8, 39, 37, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 9, 65, 190,17,86].includes(evt.keyCode))
            evt.preventDefault()
    }

    function onBlur() {
        setModel({ isfocused: false, elmntVal: app.roundNumber(model.elmntVal, CurrencyID, false) });
        if (OnBlur && typeof (OnBlur) == "function")
            OnBlur();
    }

    const handleFocus = (event: { target: { select: Function } }) => {
        event.target.select();
        setModel(old => ({ ...old, isfocused: true }))
    }
    
    let input = (<input type="text"
        id={ID ? ID : "cur" + Label}
        key={Key}
        className={`form-control form-control-sm border rounded ${IsTable ? " border-1  text-center " + TableClass : Class}`}
        disabled={Disabled}
        onChange={handler}
        onKeyDown={hanldeKeydown}
        value={model.elmntVal}
        onFocus={handleFocus}
        onBlur={onBlur}
    />)

    return (IsTable ? input :
        <div className="mt-1">
            <label className={`fw-bold  ${LabelClass}`}>{app.translate(Label)}</label>
            <div className={`input-group rounded  ${ContainerClass}`}>
                {input}
                {Icon &&
                    <button className="btn btn-light" type="button" disabled>
                        <i className={"text-primary-emphasis icofont-" + Icon} />
                    </button>
                }
            </div>
        </div>
    );
}

CurrencyInput.propTypes = {
    Label: PropTypes.string,
    Class: PropTypes.string,
    Disabled: PropTypes.bool,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    OnChange: PropTypes.func,
    Key: PropTypes.string,
    IsTable: PropTypes.bool,
    OnBlur: PropTypes.func, 
    CurrencyID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}
CurrencyInput.defaultProps = {
    Class: " ",
    LabelClass: "text-primary-emphasis",
    ContainerClass: " ",
    Disabled: false,
    Key: "input",
    IsTable: false,
    CurrencyID:1
}
export default CurrencyInput;