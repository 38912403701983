import React, { ReactNode } from 'react';
import * as app from "../services/AppService";
import { BasicProps } from "./BasicProps"

interface InputProps extends BasicProps {
     Key: string, OnEnterClicked: Function, IconClicked: Function,
    ID: string, IsTable: boolean,  Maxlength: number, LabelClass: string, ContainerClass: string, 
    Symbol: string, Placeholder: string, WithLabel: boolean, Icon: string, IconClass: string, TableClass: string
}

function Input({ OnChange, Key, OnEnterClicked, IconClicked, ID, Model, IsTable, Required, Maxlength, Label,
    LabelClass, ContainerClass, Disabled, OnBlur, Symbol, Placeholder, WithLabel, Icon, IconClass, TableClass, Class }:
    InputProps): ReactNode {

    function handler(event: { target: { value: string }}) {
        const { value } = event.target;
        if (OnChange && typeof (OnChange) == "function")
            OnChange(value, Key);
    }

    function hanldeKeydown(evt: { keyCode: number; }): void {
        if (evt.keyCode === 13 && OnEnterClicked && typeof (OnEnterClicked) == "function")
            OnEnterClicked();
    }

    function iconClicked() {
        if (IconClicked && typeof (IconClicked) == "function") IconClicked()
    }

    let input = (<input type="text" maxLength={Maxlength}
        id={ID ? ID : "inpt" + Label}
        key={Key}
        className={`form-control form-control-sm rounded ${Required && !Model ? " is-invalid  border-1" : IsTable ? " border-1 text-center bg-body-tertiary " + TableClass : "border-0 " + Class}`}
        disabled={Disabled}
        onChange={handler}
        value={Model}
        onBlur={OnBlur}
        onKeyDown={hanldeKeydown}
        placeholder={app.translate(Placeholder) + Symbol}
        required={Required && !Model}
    />)

    return (IsTable ? input :
        <div className="mt-1">
            {WithLabel && <label className={`${LabelClass}`}>{app.translate(Label)}</label>}
            <div className={`input-group border rounded  ${ContainerClass}`}>
                {input}
                {Icon &&
                    <button className={`btn btn-sm border-0 p-0 px-2 ${IconClass}`} type="button" disabled={!IconClicked} onClick={iconClicked}>
                        <i className={` iconssc-${Icon}`} />
                    </button>
                }
            </div>
        </div>
    );
}

Input.defaultProps = {
    LabelClass: "fw-semibold text-primary-emphasis",
    Type: "text",
    Disabled: false,
    Required: false,
    Key: "input",
    IsTable: false,
    WithLabel: true,
    Symbol:""
}

export default Input;